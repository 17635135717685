var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v2.15.0-2024-10-31"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { cloneDeep, isString } from 'lodash';

import ENV from './constants/ENV';
import censorPasswords from './helpers/censorPasswords';

// Let's not turn this on in production yet
if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true') {
  Sentry.init({
    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: process.env.NEXT_PUBLIC_SENTRY_ENV !== 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV || 'local',
    ignoreErrors: [
      "Unexpected token '<'",
      "undefined is not an object (evaluating '__gCrWeb.edgeTranslate.detectPageState')",
      'JSON Parse error: Unexpected identifier "cmdone"',
      'Unauthorized',
      'New App Version detected',
    ],
    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        beforeAddRecordingEvent: event => {
          const processRequest = (item: any) => {
            let newItem = cloneDeep(item);
            if (newItem) newItem = censorPasswords(item);
            return newItem;
          };

          if (event.data.tag === 'performanceSpan') {
            if (event.data.payload.op === 'resource.fetch') {
              if (event.data.payload.data?.method === 'POST') {
                const requestBody = event.data.payload.data.request.body;
                const responseBodyJSON = event.data.payload.data.response.body;

                // Handle masking the request (hide variables)
                if (requestBody) {
                  const newRequestBody = Array.isArray(requestBody)
                    ? requestBody.map(processRequest)
                    : processRequest(requestBody);
                  event.data.payload.data.request.body = newRequestBody;
                }

                // Handle masking the response (hide PII)
                if (isString(responseBodyJSON)) {
                  // Below is an example of how we could go about masking responses. Currently we don't have a need for this.
                  // const newResponseBodyJSON = responseBodyJSON
                  //   .replace(/"displayName":".+"/g, `"displayName":"***"`)
                  //   .replace(/"firstName":".+"/g, `"firstName":"***"`)
                  //   .replace(/"lastName":".+"/g, `"lastName":"***"`)
                  //   .replace(/"dateOfBirth":".+"/g, `"dateOfBirth":"***"`)
                  //   .replace(/"enrolledAtHighSchoolId":".+"/g, `"enrolledAtHighSchoolId":"***"`)
                  //   .replace(/"email":".+"/g, `"email":"***"`)
                  //   .replace(/"city":".+"/g, `"city":"***"`)
                  //   .replace(/"postalCode":".+"/g, `"postalCode":"***"`)
                  //   .replace(/"stateCode":".+"/g, `"stateCode":"***"`)
                  //   .replace(/"street1":".+"/g, `"street1":"***"`)
                  //   .replace(/"street2":".+"/g, `"street2":"***"`)
                  //   .replace(/"line2":".+"/g, `"line2":"***"`)
                  //   .replace(/"phone":".+"/g, `"phone":"***"`);
                  // event.data.payload.data.response.body = newResponseBodyJSON;
                }
              }
            }
          }

          return event;
        },
        blockAllMedia: false,
        maskAllInputs: false,
        maskAllText: false,
        networkCaptureBodies: true,
        networkDetailAllowUrls: [ENV.API_ENDPOINT_GRAPHQL || '', window.location.origin],
        stickySession: true,
      }),
    ],

    release: ENV.APP_VERSION?.replaceAll('/', '_'),

    replaysOnErrorSampleRate: 1.0,

    // Toggle for % captured in production (currently 100% in prod and other environments
    replaysSessionSampleRate: process.env.NEXT_PUBLIC_SENTRY_ENV === 'production' ? 1 : 1,
    tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_ENV === 'production' ? 1 : 1,
  });
}
